import cls from "./ScrollButton.module.css";

const ScrollButton = ({src, href, label}) => {
    return (
        <div className={cls.downContainer} onClick={() => window.location.href = `#${href}`}>
            <div className={cls.arrowButton}>
                <img src={src} alt=""/>
            </div>
            {label ? (
                <div className={cls.downText}>
                    {label}
                </div>
            ) : null}
        </div>
    )
}

export default ScrollButton;
