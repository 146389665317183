import React, { useCallback, useEffect, useRef, useState,
} from "react";
import cls from "./Modal.module.css";
const ANIMATION_DELAY = 300;

export const Modal = (props) => {
    const [isClosing, setIsClosing] = useState(false);
    const timeRef = useRef();

    const {
        className, children, isOpen, onClose,
    } = props;

    const handleClose = useCallback((e) => {
        if (e.target !== e.currentTarget) {
            if (!(e instanceof KeyboardEvent)) {
                e.stopPropagation();
                return;
            }
        }
        if (onClose) {
            setIsClosing(true);
            timeRef.current = setTimeout(() => {
                onClose();
                setIsClosing(false);
            }, ANIMATION_DELAY);
        }
    }, [onClose]);

    const onKeyDown = useCallback((e) => {
        if (e.key === "Escape") {
            handleClose(e);
        }
    }, [handleClose]);

    useEffect(() => {
        if (isOpen) {
            window.addEventListener("keydown", onKeyDown);
        }

        return () => {
            clearInterval(timeRef.current);
            window.removeEventListener("keydown", onKeyDown);
            // console.log("remove keydown listener");
        };
    }, [isOpen, onKeyDown]);

    return (
        <div className={`${cls.modal} ${isClosing ? cls.isClosing : ""} ${isOpen ? cls.opened : ""}`}>
            <div className={cls.overlay} onClick={handleClose}>
                <div className={cls.content}>
                    {children}
                </div>
            </div>
        </div>
    );
};
