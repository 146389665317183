import Paragraph from "../../shared/ui/Paragraph/Paragraph";
import {classNames} from "../../shared/lib/classNames/classNames";
import cls from "./Terms.module.css";


const Terms = () => {
    return (
        <div className="container container--main">
            <section className={classNames(cls.Terms)}>

            </section>
            <Paragraph paragraphContent={[
                {
                    type: "text",
                    content: `By downloading or using the app, these terms will automatically apply to you – please ensure that you read them carefully before using the app. You are not allowed to copy or modify the app, any part of the app, or our trademarks in any way. You are not permitted to attempt to extract the source code of the app, nor should you try to translate the app into other languages or create derivative versions. The app itself, along with all trademarks, copyrights, database rights, and other intellectual property rights related to it, remains the property of Express Lab.
Express Lab is committed to ensuring that the app is as functional and efficient as possible. For this reason, we reserve the right to make changes to the app or to charge for its services at any time for any reason. We will never charge you for the app or its services without making it very clear what you are paying for.
The app, Super Hero Skins for Minecraft, stores and processes personal data that you have provided to us to deliver our services. It is your responsibility to keep your device and access to the app secure. We strongly recommend that you do not jailbreak or root your device, which is the process of removing software restrictions and limitations imposed by the official operating system. Doing so could make your device vulnerable to malware, viruses, and malicious programs, compromise your device’s security features, and may prevent the Super Hero Skins for Minecraft app from functioning properly or at all.
The app utilizes third-party services that have their own Terms and Conditions.
Link to Terms and Conditions of third-party service providers used by the app:

`
                },
                {
                    type: "list",
                    content: ["Google Play Services", "Google Analytics for Firebase", "Firebase Crashlytics", "Branch", "Facebook"]
                },
                {
                    type: "text",
                    content: `
You should be aware that there are certain situations where Express Lab will not take responsibility. Certain app functions require an active internet connection. This connection can be via Wi-Fi or provided by your mobile network provider, but Express Lab cannot be held responsible if the app does not function at full capacity due to a lack of Wi-Fi or data allowance.
If you use the app outside an area with Wi-Fi, please remember that the terms of your agreement with your mobile network provider will still apply. You may be charged by your provider for data usage during the connection while accessing the app or for other third-party charges. By using the app, you accept responsibility for any such charges, including roaming data charges if you use the app outside your home territory without turning off data roaming. If you are not the bill payer for the device on which you are using the app, we assume you have received permission from the bill payer for using the app.
Similarly, Express Lab is not responsible for issues related to the use of the app, such as ensuring that your device stays charged. If your device runs out of battery and you are unable to use the app, Express Lab will not accept responsibility.
While we strive to ensure that the app is always up-to-date and accurate, we rely on third parties to provide information. Express Lab accepts no liability for any direct or indirect loss experienced as a result of relying solely on the app’s functionality.
There may be times when we wish to update the app. The app is currently available on Android, and system requirements (or requirements for additional systems we may support in the future) may change. You will need to download updates to continue using the app. Express Lab does not guarantee that updates will always be relevant to you or compatible with the Android version you have installed. You agree to accept updates to the app when offered. We may also discontinue providing the app and may terminate use of it without notice. Unless stated otherwise, upon termination, (a) the rights and licenses granted to you in these terms will end; and (b) you must cease using the app and delete it from your device if necessary.
Changes to This Privacy Policy
We may update our Privacy Policy periodically. You are encouraged to review this page regularly for any changes. We will notify you of any updates by posting the revised Privacy Policy on this page.
These Terms and Conditions are effective as of 2024-07-07.
Contact Us
If you have any questions or suggestions about our Privacy Policy, please contact us at info@expresslabapps.com.

`
                }
            ]}/>
        </div>
    )
}

export default Terms;