import TikTok from "../../shared/assets/images/TikTok.png";
import Youtube from "../../shared/assets/images/Youtube.png";
import Facebook from "../../shared/assets/images/Facebook.png";
import Instagram from "../../shared/assets/images/Instagram.png";
import Close from "../../shared/assets/images/close.png";
import TikTokHover from "../../shared/assets/images/TikTokHover.png";
import YouTubeHover from "../../shared/assets/images/YouTubeHover.png";
import FacebookHover from "../../shared/assets/images/FacebookHover.png";
import InstagramHover from "../../shared/assets/images/InstagramHover.png";
import cls from "./Footer.module.css";


import { Modal } from "../../components/Modal/Modal";
import { useCallback, useState } from "react";
import Title from "../../shared/ui/Title/Title";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";

const Footer = () => {
    const [isModal, setModal] = useState(false);
    const onToggleModal = useCallback(() => {
        setModal((prevState) => !prevState);
    }, []);
    return (
        <footer className={cls.footer}>
            <div className={cls.footerSocials}>
                <div className="container container--main footer__container">
                    <div className={cls.footerSocial}>
                        <div className={cls.footerCopyright}>
                            {`
                            EXPRESS LAB PTE. LTD.
                            10 ANSON ROAD #20-05,
                            INTERNATIONAL PLAZA SINGAPORE (079903)
                            UEN 202413342G`}
                        </div>
                        <div className={cls.footerSocialLinks}>
                            <a href="" className={cls.footerLink} onClick={(e) => e.preventDefault()}>
                                <img className={cls.opacity} src={TikTokHover} alt=""/>
                                <img src={TikTok} alt=""/>
                            </a>
                            <a href="" className={cls.footerLink} onClick={(e) => e.preventDefault()}>
                                <img className={cls.opacity} src={YouTubeHover} alt=""/>
                                <img src={Youtube} alt=""/>
                            </a>
                            <a href="" className={cls.footerLink} onClick={(e) => e.preventDefault()}>
                                <img className={cls.opacity} src={FacebookHover} alt=""/>
                                <img src={Facebook} alt=""/>
                            </a>
                            <a href="" className={cls.footerLink} style={{marginRight:0}} onClick={(e) => e.preventDefault()}>
                                <img className={cls.opacity} src={InstagramHover} alt=""/>
                                <img src={Instagram} alt=""/>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className={cls.footerAllrights}>
                <div className="container container--main footer__container">
                    <div className={cls.footerAllrightsWrapper}>
                        <div className={cls.footerSocialCookie} onClick={() => onToggleModal()}>
                            Cookie Policy
                        </div>
                        <div className={cls.footerAllrightsRights}>
                            ©2024EXPRESS LAB. All rights reserved.
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={isModal} onClose={onToggleModal}>
                <img className={cls.closeImg} src={Close} alt="" onClick={onToggleModal}/>
                <MarginBottomContainer margin={22}>
                    <Title title="Cookie Policy" />
                </MarginBottomContainer>

                <p className={cls.cookieP}>
                    First Party Performance and Functionality Cookies.
                </p>
                <br/>
                <p className={cls.cookieP}>
                    These cookies help us to customize and enhance your online experience with EXPRESS LAB. For example, they help us to remember your preferences and prevent you from needing to re-enter information you previously provided (for example, during applying form).  Deletion of these types of cookies may result in limited functionality of our service.
                </p>
                <br/>
                <p className={cls.cookieP}>
                    Most cookies are only active for one day. Some cookies are active for a longer time, ranging from 3 to 12 months.
                </p>
            </Modal>
        </footer>
    );
};

export default Footer;
