import cls from "./ParagraphSection.module.css";
const ParagraphSection = ({children}) => {
    return (
        <p className={cls.ParagraphSection}>
            {children}
        </p>
    )
}

export default ParagraphSection;
