import OurMissionImg from "../../shared/assets/images/ourMission.png";
import Title from "../../shared/ui/Title/Title";
import ParagraphSection from "../../shared/ui/ParagraphSection/ParagraphSection";
import cls from "./Mission.module.css";
import MarginBottomContainer from "../../shared/ui/MarginBottomContainer/MarginBottomContainer";
const OurMission = () => {
    return (
        <div id="mission" className="mission-section">
            <div className="container container--main">
                <div className={cls.missionWrapper}>
                    <div className="mission-wrapper__header">
                        <MarginBottomContainer margin={22}>
                            <Title title="Our mission" />
                        </MarginBottomContainer>
                    </div>
                    <MarginBottomContainer margin={90}>
                        <div className={cls.missionWrapperInfo}>
                            <ParagraphSection>
                                Our mission is straightforward: create apps that improve your day. We believe in the power of technology to make a difference, and our goal is to develop apps that are not only functional but also enjoyable to use. We're here to innovate and simplify, bringing you solutions that make your life better, one app at a time.
                            </ParagraphSection>
                        </div>
                    </MarginBottomContainer>

                    <div className="mission-wrapper__image">
                        <img className="img" src={OurMissionImg} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurMission;
