import cls from "./Paragraph.module.css";
import {HTMLAttributes} from "react";
import {classNames} from "../../lib/classNames/classNames";

// export type ParagraphItem =
//     { type: 'text'; content: string }
//     | { type: 'span'; content: string }
//     | { type: 'list'; content: string[] }
//     | { type: 'dot'; content: string };
//
// export interface ParagraphProps extends HTMLAttributes<HTMLParagraphElement> {
//     className?: string;
//     paragraphContent?: ParagraphItem[];
// }

const Paragraph = ({ className, paragraphContent, ...rest}) => {
    return (
        <div {...rest} className={classNames(cls.Paragraph, {}, [className])}>
            {paragraphContent.map((item, index) => {
                switch (item.type) {
                    case 'text':
                        return <p key={index}>{item.content}</p>;
                    case 'span':
                        return <span key={index} className={cls.Paragraph__span}>{item.content}</span>;
                    case 'list':
                        return (
                            <ul key={index} className={cls.Paragraph__list}>
                                {item.content.map((listItem, listIndex) => (
                                    <li key={listIndex}>{listItem}</li>
                                ))}
                            </ul>
                        );
                    case 'dot':
                        return (
                            <ul className={cls.Paragraph__list_dotted}>
                                <li>{item.content}</li>
                            </ul>
                        )
                    default:
                        return null;
                }
            })}
        </div>
    )
};

export default Paragraph;



