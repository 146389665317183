import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from "./pages/Header/Header";
import Main from "./pages/Main/Main";
import AboutUs from "./pages/AboutUs/AboutUs";
import Career from "./pages/Career/Career";
import ContactUs from "./pages/ContactUs/ContactUs";
import Footer from "./pages/Footer/Footer";
import OurMission from "./pages/OurMission/OurMission";
import Terms from "./pages/PrivacyPolicy/Terms";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy";

function App() {
    return (
        <Router>
            <div className="App">
                {/* Общий компонент Header */}
                <Header />

                <Routes>
                    {/* Главная страница со всеми секциями */}
                    <Route
                        path="/"
                        element={
                            <>
                                <Main />
                                <AboutUs />
                                <OurMission />
                                <Career />
                                <ContactUs />
                                <Footer />
                            </>
                        }
                    />

                    {/* Отдельные страницы */}
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/terms" element={<Terms />} />
                </Routes>
            </div>
        </Router>
        // <div className="App">
        //     <Header />
        //     <Main />
        //     <AboutUs />
        //     <OurMission />
        //     <Career />
        //     <ContactUs />
        //     <Footer />
        // </div>
  );
}

export default App;
