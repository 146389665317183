import cls from "./textArea.module.css";
const TextArea = ({ onChange, value, name, label, error }) => {
    const handleChange = ({ target }) => {
        onChange({ name: target.name, value: target.value });
    };



    return (
        <>
            <textarea
                placeholder={label}
                onChange={handleChange}
                className={cls.textArea}
                id={name}
                value={value}
                name={name}
            >
            </textarea>
        </>
    );
};


export default TextArea;
