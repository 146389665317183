import cls from "./Title.module.css";
const Title = ({title}) => {
    return (
        <h1 className={cls.Title}>
            {title}
        </h1>
    )
}

export default Title;
