
const MarginBottomContainer = ({children, margin}) => {
    return (
        <div style={{marginBottom: `${margin}px`}}>
            {children}
        </div>
    )
}

export default MarginBottomContainer;
